<template>
  <div class="doms">
    <div id="root2"></div>
    <div class="videoList">
      <div class="videoItem" v-for="(item, index) in videoList" :key="index">
        <video
          v-show="item.isshow"
          :ref="`video${index}`"
          :src="item.configparam"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
import { getAreaInfo } from "../api/index";
import VoiceToText from "./VoiceToText";
import rtm from "../socket/rtm";
export default {
  mixins: [VoiceToText, rtm],
  data() {
    return {
      answer: "",
      accessToken: "",
      inwidth: "",
      channelNum: "",
      videoList: [
        // {
        //   isshow: false,
        //   src: "https://mrstage-oss.oss-cn-shanghai.aliyuncs.com/nocode/20230419/钉钉随幻saas教程（更换UI）_antmxjELmn.mp4",
        //   AreaName: "公共活动区",
        // },
        // {
        //   isshow: false,
        //   src: "https://mrstage-oss.oss-cn-shanghai.aliyuncs.com/nocode/20230419/段智超-《夏日之旅》_ANvTQmxPqT.mp4",
        //   AreaName: "前厅",
        // },
      ],
      plugin: window["@shuwen/rtc-plugin"],
      pauseJiqiren: false,
    };
  },
  mounted() {
    this.loadWindow();
    getAreaInfo(this.$route.query.type || "lx").then((res) => {
      console.log(res);
      this.videoList = res.data;
      this.videoList.filter((item) => {
        item.isshow = false;
      });
      this.$nextTick(() => {
        this.videoList.map((item, index) => {
          this.$refs[`video${index}`][0].addEventListener("ended", (e) => {
            console.log("视频播放完了", index);
            this.$rtmSocket.sendMesgChannel({
              ControlType: "SwitchCameraCarousel",
              Enable: true,
              bNeedZoneSwitching: true,
            });
          });
        });
      });
      // this.init();
    });
    this.$rtmSocket.localSortMessage = (val) => {
      if (val.UEMsg === "CameraInArea") {
        this.videoList.filter((item, index) => {
          if (item.descInfo === val.AreaName) {
            if (val.IsCarousel) {
              this.videoPlay(index);
            } else {
              this.$refs[`video${index}`][0].currentTime = 0;
              this.videoPause(index);
            }
          }
        });
      } else if (val.UEMsg === "CameraInArea_Pause") {
        this.pauseJiqiren = val.IsPause;
        this.videoList.filter((item, index) => {
          if (item.descInfo === val.AreaName) {
            if (!val.IsPause) {
              this.videoPlay(index);
            } else {
              this.videoPause(index);
            }
          }
        });
      }
    };
  },
  methods: {
    virtualSpeak(val) {
      this.player.sendSpeak(val);
    },
    interruptSpeak() {
      this.player.configData.instance.wsInstance.send(
        JSON.stringify({
          vhostToken: this.accessToken, // 用户认证
          uuid: "speak", // 唯一，不同消息区分
          msgType: 12, // 请求打通连接
          data: {
            data: {
              message_type: "interrupt",
            },
          },
        })
      );
    },
    init() {
      this.plugin
        .getToken({
          ak: "18b55377ee2c4f3daf59a389bd9c527d",
          username: "root",
          password: "heheheh",
          endpoint: location.host, // 域名
        })
        .then((res) => {
          if (res && res.data && res.success) {
            this.accessToken = res.data.vhostToken;
            this.player = new this.plugin.RtcPlayer({
              pos: ["35%", "10%"], // 位置： x y
              size: ["55%", ""], // 大小： 宽 高
              streamId: "91ca42dfdf5111ed8d775db71f785691",
              // dom: document.getElementById("root2"),
              accessToken: this.accessToken, // 可通过 getToken API获取后传入，也可·直接传入
              streamType: "manyOfOne", //manyOfOne：共用一路
              endpoint: "vhost-entry.shuwen.com", // 域名，测试/正式 可能不同
            });
            this.player.on("load", () => {
              console.log("加载完成");
              console.log(this.player);
            });
            // error事件，用来监听一些错误
            this.player.on("error", (error) => {
              console.log("error", error);
              if (this.pauseJiqiren) {
                this.$rtmSocket.sendMesgChannel({
                  ControlType: "PauseSwitchCameraCarousel",
                  Enable: false,
                });
              }
            });
            // speakStart事件，用来监听主播开始说话
            this.player.on("speakStart", () => {
              // 开始说话后的一些操作，比如显示主播
              console.log("speakStart");
            });
            // speakEnd事件，用来监听主播说话结束
            this.player.on("speakEnd", () => {
              // 说话结束后的一些操作，比如隐藏主播
              console.log("speakEnd");
              if (this.pauseJiqiren) {
                this.$rtmSocket.sendMesgChannel({
                  ControlType: "PauseSwitchCameraCarousel",
                  Enable: false,
                });
              }
            });
          } else {
            console.error("登陆失败");
          }
        });
    },
    videoPlay(val) {
      this.videoList.filter((item, index) => {
        item.isshow = false;
        if (val === index) {
          item.isshow = true;
          this.$refs[`video${val}`][0].play();
        } else {
          this.$refs[`video${index}`][0].currentTime = 0;
        }
      });
      this.$forceUpdate();
    },
    videoPause(val) {
      this.$refs[`video${val}`][0].pause();
      this.videoList.filter((item, index) => {
        item.isshow = false;
      });
      this.$forceUpdate();
    },
    loadWindow() {
      window.virtualSpeak = this.virtualSpeak;
      window.interruptSpeak = this.interruptSpeak;
      window.receiveRoomId = (vals) => {
        let val = JSON.parse(vals);
        if (val.flag) {
          this.channelNum = val.roomId;
          this.wsinit();
        } else {
          this.$rtmSocket.leavechannel();
        }
      };
    },
  },
};
</script>

<style lang="less" scoped>
.doms {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: #5fbc00;
  .videoList {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999;
    .videoItem {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      video {
        width: 100%;
        height: 100%;
      }
    }
  }
  // #root2 {
  //   width: 55%;
  //   height: 100vh;
  //   position: relative;
  //   overflow: hidden;
  //   ::v-deep canvas {
  //     width: 55%;
  //     // height: 100% !important;
  //   }
  // }
}
</style>